<template><v-app><Header /><v-main><v-container fluid>
<v-row dense><v-col cols="12"><v-card>
    <v-card-title class="text-h5 green text--info white--text">{{ $t('History') }}</v-card-title>
    <v-card-subtitle class="green white--text">{{ $t('Aktivitas yang telah terjadi') }}</v-card-subtitle>
    <v-card-text class="text--primary mt-2">
        <v-row dense v-for="(datat, index) in datatables" :key="datat.id" :id="index"><v-col cols="12"><v-card><v-card-text>
            <v-row class="blue white--text font-weight-bold" >
                <v-col cols="6" md="3">{{ $t('Tanggal') }}</v-col>
                <v-col cols="6" md="3">{{ datat.waktu }}</v-col>
                <v-col cols="6" md="3">{{ $t('Nama file') }}</v-col>
                <v-col cols="6" md="3">{{ datat.filename }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">{{ $t('Nama buku') }}</v-col>
                <v-col cols="6" md="3">{{ datat.title_book }}</v-col>
            </v-row>
        </v-card-text></v-card></v-col></v-row>
    </v-card-text>
</v-card></v-col></v-row>
</v-container></v-main><Footer /></v-app></template>
<script>
import Header from './layout/Header';
import Footer from './layout/Footer';

  export default {
    components: { Header, Footer },
    data: () => ({
        ajaxProcess:false,
        datatables:[],
    }),
    methods: {
        refreshTable() {
            var vue = this;
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
                        
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/history-download-table', requestOptions)
            .then(async response => {
                vue.datatables = await response.json();
                vue.ajaxProcess = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        }
    },
    mounted() {
        this.refreshTable();   
    }
  }
</script>
